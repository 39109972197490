import React, { FC } from "react";
import { getSrc } from "gatsby-plugin-image";
import { find } from "lodash";

import { Container, PageHero } from "components";
import { Contact, HeaderMenu, SEO, SEOProps } from "containers";
import { DefaultLayout } from "layouts";
import { useMediaQuery } from "graphql";

import { DefaultPageProps } from "../types";

export const FeedTemplate: FC<DefaultPageProps> = ({
  children,
  isMDX = true,
  location,
  pageContext: {
    excerpt,
    frontmatter: {
      brand,
      description,
      hero,
      header,
      layout,
      main,
      page,
      seo,
      showFooter = true,
      showHeader = true,
      subtitle,
      title,
      contact,
    },
  },
}) => {
  const media = useMediaQuery();
  const image = find(media, ["name", `${page}-hero`]);
  const fallbackImage = find(media, ["name", "home-hero"]);

  const seoProps: SEOProps = {
    title: seo?.title || title,
    description: seo?.description
      ? seo?.description
      : description
      ? description
      : excerpt,
    image: image
      ? getSrc(image.childImageSharp.gatsbyImageData)
      : getSrc(fallbackImage?.childImageSharp.gatsbyImageData),
    url: location?.href,
    ...seo,
  };

  return (
    <>
      <SEO {...seoProps} />
      <DefaultLayout
        brand={brand}
        header={{
          right: <HeaderMenu {...header?.menu} />,
          ...header,
        }}
        location={location}
        isMDX={isMDX}
        main={main}
        {...layout}
      >
        {showHeader && (
          <PageHero
            color="contrast"
            {...hero}
            caption={{
              heading: title,
              subheading: subtitle,
              ...hero?.caption,
            }}
            media={
              image && {
                image,
                ...hero?.media,
              }
            }
          />
        )}
        {main?.is === "content" ? (
          <Container
            as="article"
            is="content"
            className="py-8 md:py-12 xl:py-16"
          >
            {children}
          </Container>
        ) : (
          children
        )}
        {showFooter && <Contact location={location} {...contact} />}
      </DefaultLayout>
    </>
  );
};

export default FeedTemplate;
