import React, { FC } from "react";

import {
  DefaultPageProps,
  FeedTemplate,
  PageContext,
  PageTemplate,
  PanelTemplate,
  PanelTemplateProps,
} from "templates";

const safelyGetFrontMatter = (pageContext: PageContext) =>
  pageContext && pageContext.frontmatter ? pageContext.frontmatter : {};

const DefaultTemplate: FC<DefaultPageProps> = (props) => {
  const { template } = safelyGetFrontMatter(props.pageContext);

  switch (template) {
    case "feed":
      return <FeedTemplate {...(props as DefaultPageProps)} isMDX />;
    case "panel":
      return <PanelTemplate {...(props as PanelTemplateProps)} isMDX />;
    case "page":
    default:
      return <PageTemplate {...(props as DefaultPageProps)} isMDX />;
  }
};

export default DefaultTemplate;
