import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default/DefaultTemplate.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Services = makeShortcode("Services");
const About = makeShortcode("About");
const Values = makeShortcode("Values");
const Experts = makeShortcode("Experts");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Services mdxType="Services" />
    <About mdxType="About" />
    <Values mdxType="Values" />
    <Experts mdxType="Experts" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      